<template>
  <b-card>
    <b-form @submit.prevent>
      <app-collapse>
        <app-collapse-item title="Búsqueda">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <h5 class="font-weight-bold">

                </h5>

              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Empresa
                </h5>

                  <autocomplete-infinity
                      url="contact-center/empresas-activas"
                      placeholder="Seleccione Empresa"
                      @item-selected="selectEmpresa"
                      label="razon_social_empresa"
                      id="empresa"
                      :selectedEmpresa="filter.selectedEmpresa"
                      itemsPerPage="10"
                  ></autocomplete-infinity>

              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Ficha
                </h5>
                <autocomplete-infinity
                    url="contact-center/ficha"
                    placeholder="Seleccione Ficha"
                    @item-selected="selectFicha"
                    label="num_ficha"
                    id="id_ficha"
                    :selectedFicha="filter.selectedFicha"
                    itemsPerPage="10"
                ></autocomplete-infinity>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Estado
                </h5>

                  <v-select
                      item-text="title"
                      item-value="id"
                      v-model="filter.selectedAsignado"
                      label="title"
                      :options="optionAsignado"
                  />

              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Teletutor
                </h5>

                <autocomplete-infinity
                    url="contact-center/usuario-contact"
                    placeholder="Seleccione Teletutor"
                    @item-selected="selectTeletutor"
                    label="nombre_contact"
                    id="teletutor"
                    itemsPerPage="10"
                ></autocomplete-infinity>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- checkbox button  -->
              <b-form-checkbox
                      v-model="filter.cursos_cerrados"
                      name="check-button"
                      switch
                      inline
              >
                Cerradas Ultimo 2 meses
              </b-form-checkbox>
            </b-col>

            <b-col md="12">
              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  type="submit"
                  @click="$emit('buscar', filter)"
                >

                  <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Buscar</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="d-none"
                  variant="outline-primary"
                  type="submit"
                >

                  <feather-icon
                    icon="RefreshCcwIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Limpiar</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

export default {
  name: 'FiltrosTeletutoria',
  components: {
    AppCollapse,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadio,
    flatPickr,
    AppCollapseItem,
    BFormDatepicker,
    vSelect,

  },
  directives: {
    Ripple,
  },
  props: [''],
  data() {
    return {
      filter: {
        selectedEmpresa: null,
        selectedFicha: null,
        selectedAsignado: { title: 'Todos', id: '0' },
        selectedTeletutor: null,
        cursos_cerrados:0,

      },
      empresaSelect: false,
      optionAsignado: [{ title: 'Todos', id: '0' }, { title: 'Asignado', id: '1' }, { title: 'No Asignado', id: '2' }],

    }
  },
  computed: {
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default.es,
        dateFormat: 'd-m-Y',
        wrap: true,
      }
    },
    optionFicha() {
      this.optionFichas = this.$store.state.teletutoria.optionFicha
      return this.optionFichas.map(item => ({
        title: item.num_ficha,
        id: item.id_ficha,
      }))
    },
  },
  methods: {
    selectEmpresa(empresa) {
      this.filter.selectedEmpresa = empresa != null ? empresa.id_empresa : null
      this.$store.dispatch('teletutoria/getOptionFicha', this.filter.selectedEmpresa)
      this.filter.selectedEmpresa != null ? this.empresaSelect = true : this.empresaSelect = false
    },
    selectFicha(ficha) {
      this.filter.selectedFicha= ficha != null ? ficha.id_ficha : null
    },
    selectTeletutor(teletutor) {
      this.filter.selectedTeletutor = teletutor != null ? teletutor.id_usuario : null
    },

  },
}
</script>

<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .demo-inline-spacing.curso .custom-radio {
        margin-top: 0.5rem !important;
    }

    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px!important;
        color: #6e6b7b;
    }
</style>
